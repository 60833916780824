export default {
  data() {
    return {
      ReviewsListDesktop: [
        {
          imgHref: 'main/main--reviews/reviews-author-1',
          text: `Вся золотодобыча идет в\u00A0Забайкалье, а\u00A0топ-менеджмент находится в\u00A0Москве — команде холдинга нужна стабильная и\u00A0регулярная связь. После ухода иностранных компаний с\u00A0российского рынка мы лишились привычной коммуникации, но\u00A0нам удалось быстро восстановить рабочие процессы благодаря сервисам МТС\u00A0Линк.
`,
          author: {
            name: 'Андрей Журко',
            position: `Генеральный директор\u000A«Мангазея Технолоджи»`,
          },
        },
        {
          imgHref: 'main/main--reviews/reviews-author-2',
          text: `Мы сделали выбор в\u00A0пользу МТС\u00A0Линк благодаря достойному уровню защиты персональных данных и\u00A0безопасности, а\u00A0также полной локализации и\u00A0отсутствию рисков трансграничной передачи данных. В\u00A0текущих условиях это главные требования к\u00A0коммуникационной площадке в\u00A0рамках фарминдустрии.`,
          author: {
            name: 'Татьяна Филинова',
            position: `Руководитель направления развития цифровых\u000Aкоммуникаций компании «АКРИХИН»`,
          },
        },
        {
          imgHref: 'main/main--reviews/reviews-author-3',
          text: `Мы работаем с\u00A0разными онлайн-сервисами, поэтому можем сказать — техподдержка сервиса МТС\u00A0Линк Встречи действительно лучшая. Специалисты всегда приходят на\u00A0помощь в\u00A0течение 1–2 минут и\u00A0отлично справляются с\u00A0вопросами любой сложности, а\u00A0главное — не\u00A0пропадают на\u00A0неизвестный срок и\u00A0комментируют действия.`,
          author: {
            name: 'Анастасия Гордеева',
            position: `Маркетинг-менеджер рекламного\u000Aагентства «Ре\u00A0Медиа»`,
          },
        },
      ],
      //Два списка так как разные версии переносов в долдностях от 22.11.2024 (если контент поменяется второй список можнно удалить, и оставить единый)
      ReviewsListMobile: [
        {
          imgHref: 'main/main--reviews/reviews-author-1',
          text: `Вся золотодобыча идет в\u00A0Забайкалье, а\u00A0топ-менеджмент находится в\u00A0Москве — команде холдинга нужна стабильная и\u00A0регулярная связь. После ухода иностранных компаний с\u00A0российского рынка мы лишились привычной коммуникации, но\u00A0нам удалось быстро восстановить рабочие процессы благодаря сервисам МТС\u00A0Линк.
`,
          author: {
            name: 'Андрей Журко',
            position: `Генеральный директор\u000A«Мангазея Технолоджи»`,
          },
        },
        {
          imgHref: 'main/main--reviews/reviews-author-2',
          text: `Мы сделали выбор в\u00A0пользу МТС\u00A0Линк благодаря достойному уровню защиты персональных данных и\u00A0безопасности, а\u00A0также полной локализации и\u00A0отсутствию рисков трансграничной передачи данных. В\u00A0текущих условиях это главные требования к\u00A0коммуникационной площадке в\u00A0рамках фарминдустрии.`,
          author: {
            name: 'Татьяна Филинова',
            position: `Руководитель направления развития цифровых коммуникаций компании «АКРИХИН»`,
          },
        },
        {
          imgHref: 'main/main--reviews/reviews-author-3',
          text: `Мы работаем с\u00A0разными онлайн-сервисами, поэтому можем сказать — техподдержка сервиса МТС\u00A0Линк Встречи действительно лучшая. Специалисты всегда приходят на\u00A0помощь в\u00A0течение 1\u20112\u00A0минут и\u00A0отлично справляются с\u00A0вопросами любой сложности, а\u00A0главное — не\u00A0пропадают на\u00A0неизвестный срок и\u00A0комментируют действия.`,
          author: {
            name: 'Анастасия Гордеева',
            position: `Маркетинг-менеджер рекламного\u000Aагентства «Ре\u00A0Медиа»`,
          },
        },
      ],
    }
  },
}
